:root {
  --lsconf-theme-video-background: #202124;
  --lsconf-theme-video-rec: #d50000;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  height: calc(100 * var(--vh));
  touch-action: none;
  -ms-touch-action: none;
  background-color: #f7f7fa;
}

.large-cursor {
  cursor: url("../images/cursor.png"), auto;
}

/* ダブルクリックなどによるテキスト選択を抑制する */
.user-select-none {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
